import React from 'react'

export default function Footer() {
    return (
        <footer className="bg-gray-900 text-white px-6 py-8">
            <section className="text-xs space-y-3">
                <h3 className="text-base">Disclaimer</h3>
                <div className="flex flex-col md:flex-row gap-4">
                    <p>Megaboxofficial.com uses the the app and apk provided by the developers of Megabox HD. We are not the original developers.</p>
                </div>
            </section>
            <div className="mt-10 text-center flex gap-4">
                <a href="/">&copy; Megabox HD</a>
                <a href="/contact/">Contact</a>
                <a href="/privacy-policy/">Privacy Policy</a>
            </div>
        </footer>
    )
}
