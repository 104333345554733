import React from 'react'
import { Link } from "gatsby"

export default function Header() {
    return (
        <header className="flex items-center justify-between bg-gray-900 text-white px-4 py-2">
            <Link to="/">
                <h1 className="text-xl">Megabox HD</h1>
            </Link>
            <nav className="flex flex-col md:flex-row gap-2 menu">
                <Link to="/apk/">Android</Link>
                <Link to="/megabox-hd-ios/">iOS</Link>
                <Link to="/megabox-hd-for-pc/">Windows PC</Link>
            </nav>
        </header>
    )
}
