import React from 'react'
import Header from './header'
import Footer from './footer'

export default function Layout({children}) {
	return (
		<div className="bg-gray-100">
			<div className="container mx-auto bg-white">
				<Header />
				<div className="lg:px-16 px-4 py-6">
					{children}
				</div>
				<Footer />
			</div>
		</div>
	)
}
